export const OCCASION_ENDPOINT = '/occasion';
export const USER_ENDPOINT = '/user';
export const ROLE_ENDPOINT = '/role';
export const ANIMAL_ENDPOINT = '/animal';
export const MEMBERSHIP_ENDPOINT = '/membership';
export const MEMBERSHIP_TYPES_ENDPOINT = '/membership/types';
export const DONOR_ENDPOINT = '/donor';
export const DONOR_WITHOUT_EMAIL_ENDPOINT = '/donor/without-email';
export const DONOR_WITH_EMAIL_ENDPOINT = '/donor/with-email';
export const REPORT_ENDPOINT = '/report';
export const DONATION_ENDPOINT = '/donation';
