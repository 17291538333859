import { createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import qs from 'qs';
// utils
import axios from '../../utils/axios';
import {
  DONOR_WITHOUT_EMAIL_ENDPOINT,
  DONOR_WITH_EMAIL_ENDPOINT,
  DONATION_ENDPOINT,
  DONOR_ENDPOINT,
} from 'src/constants/api-endpoints';
import { parseBody } from 'src/backend/common';

// ----------------------------------------------------------------------
const { VITE_API_BASE_URL } = import.meta.env;

const initialState = {
  isLoading: false,
  error: null,
  donorsList: [],
  donations: [],
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DONORS WITHOUT EMAIL
    getAllDonorsWithoutEmail(state, action) {
      state.isLoading = false;
      state.donorsList = [...state.donorsList, ...action.payload.items];
      state.count = action.payload.count;
    },

    // GET DONORS WITH EMAIL
    getAllDonorsWithEmail(state, action) {
      state.isLoading = false;
      state.donorsList = [...state.donorsList, ...action.payload.items];
      state.count = action.payload.count;
    },

    // GET DONATIONS FOR SPECIFIC ASSEST TYPE
    getAppealSummaryDetailsSuccess(state, action) {
      state.isLoading = false;
      state.donations = [...state.donations, ...action.payload.items];
      state.count = action.payload.count;
    },

    // GET DONATIONS FOR SPECIFIC CAMPAIGN
    getCampaignSummaryDetailsSuccess(state, action) {
      state.isLoading = false;
      state.donations = [...state.donations, ...action.payload.items];
      state.count = action.payload.count;
    },

    // GET DONOR RECOGNITION DETAILS
    getDonorRecognitionDetailsSuccess(state, action) {
      state.isLoading = false;
      state.donorsList = [...state.donorsList, ...action.payload.items];
      state.count = action.payload.count;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllDonorsWithoutEmail(filters) {
  return async (dispatch) => {
    try {
      const query = qs.stringify(filters);
      const response = await axios.get(`${DONOR_WITHOUT_EMAIL_ENDPOINT}?${query}`);
      dispatch(slice.actions.getAllDonorsWithoutEmail(response.data));
      return parseBody(response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllDonorsWithEmail(filters) {
  return async (dispatch) => {
    try {
      const query = qs.stringify(filters);
      const response = await axios.get(`${DONOR_WITH_EMAIL_ENDPOINT}?${query}`);
      dispatch(slice.actions.getAllDonorsWithEmail(response.data));
      return parseBody(response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAppealSummaryDetails(filters) {
  return async (dispatch) => {
    try {
      const query = qs.stringify(filters);
      const response = await axios.get(`${DONATION_ENDPOINT}/fetch-pdf-donations?${query}`);
      dispatch(slice.actions.getAppealSummaryDetailsSuccess(response.data));
      return parseBody(response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCampaignSummaryDetails(filters) {
  return async (dispatch) => {
    try {
      const query = qs.stringify(filters);
      const response = await axios.get(`${DONATION_ENDPOINT}/get-campaign-summary?${query}`);
      dispatch(slice.actions.getCampaignSummaryDetailsSuccess(response.data));
      return parseBody(response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDonorRecognitionDetails(filters) {
  return async (dispatch) => {
    try {
      const query = qs.stringify(filters);
      const response = await axios.get(
        `${DONOR_ENDPOINT}/get-donors-first-donation-by-month?${query}`
      );
      dispatch(slice.actions.getDonorRecognitionDetailsSuccess(response.data));
      return parseBody(response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function generatePdf(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${VITE_API_BASE_URL}report/generate-pdf`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: params?.type,
          startDate: params?.startDate,
          endDate: params?.endDate,
          appealId: params?.appealId,
        }),
      });

      const csvBlob = await response.blob();
      const url = window.URL.createObjectURL(csvBlob);

      if (params?.isPreviewMode) {
        return window.open(url, '_blank');
      }

      saveAs(url, `${params?.type}.pdf`);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function generateExcel(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${VITE_API_BASE_URL}report/generate-excel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: params?.type,
          startDate: params?.startDate,
          endDate: params?.endDate,
          appealId: params?.appealId,
        }),
      });

      const csvBlob = await response.blob();
      const url = window.URL.createObjectURL(csvBlob);

      saveAs(url, `${params?.type}.csv`);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}
